import { apicall } from "./apicall";

export const addUpdateLoanParameter = async (
  data: NewLoanParameter,
  id: string,
) => {
  const isUpdate = id?.length > 0;

  return apicall({
    endpoint: isUpdate ? "/loan/admin/setUp/update" : "/loan/admin/setUp/add",
    param: isUpdate ? id : "",
    method: isUpdate ? "PATCH" : "POST",
    auth: true,
    body: { ...data },
  });
};

export const getLoanParameter = () => {
  return apicall({
    endpoint: "/loan/admin/setup/get",
    method: "GET",
    auth: true,
  });
};

export const getLoanRequestById = (id: string) => {
  return apicall({
    endpoint: "/loan/requestById",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const toggleLoanParameter = (id: string) => {
  return apicall({
    endpoint: "/loan/admin/setUp/toggleStatus",
    param: id,
    method: "PATCH",
    auth: true,
  });
};

export const deleteLoanParameter = (id: string) => {
  return apicall({
    endpoint: "/loan/admin/setUp/delete",
    param: id,
    method: "DELETE",
    auth: true,
  });
};

export const approveLoan = (id: string) => {
  return apicall({
    endpoint: "/loan/admin/request/approve",
    param: id,
    method: "PATCH",
    auth: true,
  });
};

export const declineLoan = (id: string, comment: string) => {
  return apicall({
    endpoint: "/loan/admin/request/decline",
    param: id,
    method: "PATCH",
    auth: true,
    body: {
      comment,
    },
  });
};

export const getLoanRequestHistory = (
  query: LoanQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/loan/requestHistory",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const addUpdateReminder = (data: MessageSchedule[]) => {
  return apicall({
    endpoint: "/loan/admin/reminderMessage/add",
    method: "POST",
    body: data,
    auth: true,
  });
};

export const getReminders = () => {
  return apicall({
    endpoint: "/loan/admin/reminderMessage/get",
    method: "GET",
    auth: true,
  });
};
