import { cx } from "core/helpers/generalHelpers";

export default function LoanInfo({
  name,
  value,
  style,
  nameStyle,
}: {
  name?: string;
  value: string | number;
  style?: string;
  nameStyle?: string;
}) {
  return (
    <div
      className={cx(
        "mb-5 flex overflow-hidden rounded-[4px] border-[1px] border-[#9A67BC]",
        style!,
      )}
    >
      <p
        className={cx(
          "w-[120px] border-r border-r-[#9A67BC] bg-[#f3e8f7] px-2 py-3 text-[14px] font-light leading-none text-[#31054E]",
          nameStyle!,
        )}
      >
        {name}
      </p>
      <p className="px-2 py-3 text-[14px] font-light leading-none text-[#31054E]">
        {value}
      </p>
    </div>
  );
}
