import loansImg from "assets/icons/loans.svg";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { btn } from "core/consts/styling";
import { cx } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLoanStore from "core/services/stores/useLoanStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MultiSelectField from "core/components/formfields/MultiSelectField";
import useLocationStore from "core/services/stores/useLocationStore";

const LoanParameters = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const loanParameter = useLoanStore((store) => store.loanParameter);
  const getLoanParameterAction = useLoanStore(
    (store) => store.getLoanParameter,
  );
  const addUpdateParameterAction = useLoanStore(
    (store) => store.addUpdateLoanParameter,
  );

  const [isAll, setIsAll] = useState("true");
  const [formData, setFormData] = useState<NewLoanParameter>({
    loanTitle: "",
    loanDescription: "",
    loanTerms: "",
    minimumMonthlyTransactionCount: 0,
    eligibilityPercentage: 0,
    eligibilityMonthlyCount: 0,
    minimumAmount: 0,
    maximumAmount: 0,
    interestRate: 0,
    defaultInterestRate: 0,
    tenorInMonths: 1,
    applyToAllLocations: true,
    requiredDocuments: [""],
    locationIds: [],
  });

  const [errors, setErrors] = useState({
    LoanTitle: [],
    LoanDescription: [],
    LoanTerms: [],
    MinimumMonthlyTransactionCount: [],
    EligibilityPercentage: [],
    EligibilityMonthlyCount: [],
    MinimumAmount: [],
    MaximumAmount: [],
    InterestRate: [],
    DefaultInterestRate: [],
    TenorInMonths: [],
    RequiredDocuments: [],
    LocationIds: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onDocChange = (e: any, index: number) => {
    const { value }: any = e?.target;
    const data: string[] = [...formData?.requiredDocuments];
    data[index] = value;
    setFormData((state: any) => ({ ...state, requiredDocuments: data }));
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addNewDoc = () => {
    setFormData((state) => ({
      ...state,
      requiredDocuments: [...state.requiredDocuments, ""],
    }));
  };

  const removeDoc = (index: number) => {
    const data = [...formData.requiredDocuments];
    data.splice(index, 1);
    setFormData((state: any) => ({ ...state, requiredDocuments: data }));
  };

  const validation = (data: NewLoanParameter) => {
    var isValid = true;

    if (data?.loanTitle?.length < 1) {
      isValid = false;
      onErrorChange("LoanTitle", ["Loan Title is required"]);
    }

    if (data?.loanDescription?.length < 1) {
      isValid = false;
      onErrorChange("LoanDescription", ["Loan Description is required"]);
    }

    if (data?.loanTerms?.length < 1) {
      isValid = false;
      onErrorChange("LoanTerms", ["Loan Terms is required"]);
    }

    if (data?.tenorInMonths < 1) {
      isValid = false;
      onErrorChange("TenorInDays", ["Tenor must not be less than 1 month"]);
    }

    return isValid;
  };

  const addUpdateParameter = async (e: any) => {
    e.preventDefault();

    if (validation(formData)) {
      var selectedLocations = [];

      if (isAll === "true") {
        selectedLocations = [];
      } else {
        selectedLocations = formData?.locationIds?.map(
          (item: any) => item.value,
        );
      }

      var res: any | UIResponse = await addUpdateParameterAction(
        {
          ...formData,
          locationIds: selectedLocations,
          applyToAllLocations: isAll === "true",
        },
        loanParameter != null ? loanParameter?.id : "",
      );

      if (res?.isSuccessful) {
      } else {
        //set errors
        setErrors((state) => ({
          ...state,
          ...res?.data,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoanParameterAction();
      await getLocationAction();
    };

    fetchData();
  }, [getLoanParameterAction]);

  useEffect(() => {
    if (loanParameter) {
      const locIds =
        loanParameter?.loanLocations?.length > 0
          ? loanParameter?.loanLocations?.map((loc) => ({
              label: loc?.name,
              value: loc?.locationId,
            }))
          : [];

      setFormData({
        ...loanParameter,
        locationIds: locIds,
      });

      setIsAll(loanParameter?.applyToAllLocations ? "true" : "false");
    }
  }, [loanParameter]);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[28px] flex flex-col justify-start lg:flex-row lg:justify-between">
        <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
          <img src={loansImg} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Loan Service Management / Parameters
          </h5>
        </div>
        <div className="flex items-center gap-5">
          <Link
            className="text-brand hover:underline"
            to="/superadmin/loan-management"
          >
            Loan Service Management
          </Link>
          <Link
            className="text-brand hover:underline"
            to="/superadmin/loan-management/settings"
          >
            Settings
          </Link>
        </div>
      </header>

      <form
        onSubmit={addUpdateParameter}
        className="mb-[24px] flex flex-col-reverse gap-5 lg:flex-row"
      >
        <div className="w-full lg:w-1/2">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="Loan title"
            label="Loan Title"
            name="loanTitle"
            value={formData?.loanTitle}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.LoanTitle[0]}
            onBlur={() => onErrorChange("LoanTitle", [])}
          />

          <TextField
            boxStyle="mb-[18px]"
            label="Description"
            placeholder="Description"
            name="loanDescription"
            value={formData?.loanDescription}
            onChange={(e: any) => onFormChange(e)}
            rows={5}
            error={errors?.LoanDescription[0]}
            onBlur={() => onErrorChange("LoanDescription", [])}
          />

          <div className="mb-[16px] flex items-center justify-between">
            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isAll === "true"}
                onChange={() => {
                  setIsAll("true");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Apply to all Locations
              </label>
            </fieldset>

            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isAll === "false"}
                onChange={() => {
                  setIsAll("false");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Apply to Selected Locations
              </label>
            </fieldset>
          </div>

          {isAll === "false" && (
            <MultiSelectField
              multiple
              isRequired
              label="Select Location"
              name="locationIds"
              boxStyle="mb-[24px]"
              value={formData?.locationIds}
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        label: location.name,
                        value: location.id,
                      })),
                    ]
                  : []
              }
              onChange={(e: any) => {
                setFormData((state) => ({
                  ...state,
                  locationIds: e,
                }));
              }}
              error={errors?.LocationIds[0]}
              onBlur={() => onErrorChange("LocationIds", [])}
            />
          )}

          <p className="mb-2 font-semibold text-brandblue">Algorithm Input</p>

          <InputField
            boxStyle="mb-[18px]"
            label="Loan Interest Rate"
            name="interestRate"
            value={formData?.interestRate}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.InterestRate[0]}
            onBlur={() => onErrorChange("InterestRate", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Loan Duration (months)"
            name="tenorInMonths"
            value={formData?.tenorInMonths}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.TenorInMonths[0]}
            onBlur={() => onErrorChange("TenorInMonths", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Late Penalty Rate"
            name="defaultInterestRate"
            value={formData?.defaultInterestRate}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.DefaultInterestRate[0]}
            onBlur={() => onErrorChange("DefaultInterestRate", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Eligibility Percentage"
            name="eligibilityPercentage"
            value={formData?.eligibilityPercentage}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.EligibilityPercentage[0]}
            onBlur={() => onErrorChange("EligibilityPercentage", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Eligibility Period"
            name="eligibilityMonthlyCount"
            value={formData?.eligibilityMonthlyCount}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.EligibilityMonthlyCount[0]}
            onBlur={() => onErrorChange("EligibilityMonthlyCount", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Minimum Transaction Count"
            name="minimumMonthlyTransactionCount"
            value={formData?.minimumMonthlyTransactionCount}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.MinimumMonthlyTransactionCount[0]}
            onBlur={() => onErrorChange("MinimumMonthlyTransactionCount", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Minimum Amount"
            name="minimumAmount"
            value={formData?.minimumAmount}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.MinimumAmount[0]}
            onBlur={() => onErrorChange("MinimumAmount", [])}
          />

          <InputField
            boxStyle="mb-[18px]"
            label="Maximum Amount"
            name="maximumAmount"
            value={formData?.maximumAmount}
            type="number"
            onChange={(e: any) => onFormChange(e)}
            error={errors?.MaximumAmount[0]}
            onBlur={() => onErrorChange("MaximumAmount", [])}
          />

          <p className="mb-2 font-semibold text-brandblue">
            Required Documents
          </p>

          {formData?.requiredDocuments?.length > 0 &&
            formData?.requiredDocuments?.map((doc, index: number) => (
              <div
                key={index}
                className="item-center mb-[18px] flex items-center justify-between gap-3"
              >
                <InputField
                  boxStyle="w-2/3"
                  inputStyle="!mb-0"
                  label={`${index + 1}. Required Document`}
                  placeholder=""
                  name={`requiredDocuments-${index}`}
                  value={doc}
                  onChange={(e: any) => onDocChange(e, index)}
                />

                <div className="mt-6 flex w-1/3 items-center gap-3">
                  <button
                    type="button"
                    className={`${btn} !w-1/2 border !border-[#6C18A4] !bg-[#DFA1F4] !text-black`}
                    onClick={() => addNewDoc()}
                  >
                    Add
                  </button>

                  {formData?.requiredDocuments?.length > 1 && (
                    <button
                      type="button"
                      className={`${btn} !w-1/2 border !border-red-500 !bg-red-200 !text-black`}
                      onClick={() => removeDoc(index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}

          <TextField
            boxStyle="mb-[18px] block lg:hidden"
            label="Terms & Conditions"
            placeholder="Legal terms of loan offer"
            name="loanTerms"
            value={formData?.loanTerms}
            onChange={(e: any) => onFormChange(e)}
            rows={10}
            error={errors?.LoanTerms[0]}
            onBlur={() => onErrorChange("LoanTerms", [])}
          />

          <button className={cx(btn, "w-full bg-brand text-white")}>
            {loanParameter != null ? "Update details" : "Add Loan parameter"}
          </button>
        </div>
        <div className="hidden w-full lg:block lg:w-1/2">
          <>
            <TextField
              boxStyle="mb-[18px]"
              label="Terms & Conditions"
              placeholder="Legal terms of loan offer"
              name="loanTerms"
              value={formData?.loanTerms}
              onChange={(e: any) => onFormChange(e)}
              rows={5}
              error={errors?.LoanTerms[0]}
              onBlur={() => onErrorChange("LoanTerms", [])}
            />
          </>
        </div>
      </form>
    </section>
  );
};

export default LoanParameters;
