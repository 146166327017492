import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addUpdateBusStops,
  addUpdateBusTrip,
  addUpdateDeliveryConvenienceFee,
  addUpdateDeliveryLocation,
  addUpdatePlannedTrip,
  addUpdateService,
  addUpdateTransportFare,
  addUpdateTransportationAgent,
  addUpdateVehicle,
  addUpdateWeightRange,
  assignDriverToTrip,
  assignDriverToVehicle,
  assignToDispatch,
  cancelDelivery,
  deleteBusStop,
  deleteConvenienceFee,
  deleteLocation,
  deleteService,
  deleteTransportFare,
  deleteTransportNotification,
  deleteTrip,
  deleteVehicle,
  deleteWeight,
  getAgentDeliveryHistory,
  getAllBusStops,
  getAllTransportFares,
  getAllTrips,
  getAvailableConvenienceFees,
  getAvailableLocations,
  getAvailableServices,
  getAvailableWeights,
  getBusStopById,
  getDeliveryOrderItem,
  getDeliveryOrders,
  getDriverTripHistories,
  getPlannedTrips,
  getProfiledAgent,
  getProfiledDrivers,
  getTransportFareById,
  getTransportNotifications,
  getTransportOrder,
  getTransportOrderById,
  getTransportationAnalytics,
  getTripById,
  getTripsAssignedToDriver,
  getVehicleById,
  getVehicles,
  markAllTransportNotificationAsRead,
  markTransportNotificationAsRead,
} from "../api/transportation.api";
import { TRANSPORTATION_AGENTS_TYPE } from "core/consts/systemConst";

type StoreState = {
  isLoading: boolean;
  analytics: TransportationAnalytics;
  agent: DeliveryAgent | Driver | any;
  deliveryAgents: DeliveryAgent[];
  drivers: Driver[];
  deliveryHistories: DeliveryHistory[];
  driverTrips: DriverAssignedTrip[];
  services: TransportationService[];
  convenienceFees: DeliveryConvenienceFee[];
  weights: DeliveryWeight[];
  locations: DeliveryLocation[];
  busStops: BusStop[];
  busTrips: BusTrip[];
  busStop: BusStop | any;
  busTrip: BusTrip | any;
  tFares: TransportFare[];
  tFare: TransportFare | any;
  deliveryOrderList: {
    orders: DeliveryOrder[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  deliveryOrderItem: DeliveryOrderItem | null;
  vehicles: Vehicle[];
  vehicle: Vehicle | null;
  plannedTripList: {
    plannedTrips: PlannedTrip[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  tripRecord: TripRecord | any;
  notifications: TransportNotification[];
  transportOrders: TransportOrder[];
  transportOrderDetail: TransportOrderDetail | null;
  setLoading: (isLoading: boolean) => Promise<void>;
  getAnalytics: (query: TransportationQuery) => Promise<void>;
  getDeliveryAgents: () => Promise<void>;
  getDrivers: () => Promise<void>;
  getAgents: (type: number) => Promise<void>;
  getDriverTrips: (agentId: string) => Promise<void>;
  getDeliveryHistories: (agentId: string) => Promise<void>;
  setAgent: (agent: any) => Promise<void>;
  addUpdateAgent: (
    agent: NewTransportationAgent,
    agentId: string,
  ) => Promise<UIResponse>;
  addUpdateService: (
    service: NewService,
    serviceId: string,
  ) => Promise<UIResponse>;
  addUpdateDeliveryLocation: (
    location: NewDeliveryLocation,
    locationId: string,
  ) => Promise<UIResponse>;
  addUpdateConvenienceFee: (
    fee: NewConvenienceFee,
    feeId: string,
  ) => Promise<UIResponse>;
  addUpdateWeight: (
    weight: NewWeightRange,
    weightId: string,
  ) => Promise<UIResponse>;
  getServices: () => Promise<void>;
  getDeliveryLocations: () => Promise<void>;
  getWeights: () => Promise<void>;
  deleteService: (serviceId: string) => Promise<void>;
  deleteWeight: (weightId: string) => Promise<void>;
  deleteConvenienceFee: (feeId: string) => Promise<void>;
  deleteLocation: (locationId: string) => Promise<void>;
  getConvenienceFees: () => Promise<void>;
  addUpateBusStop: (
    busStop: NewBusStop,
    busStopId: string,
  ) => Promise<UIResponse>;
  deleteBusStop: (busStopId: string) => Promise<void>;
  getBusStops: () => Promise<void>;
  getBusStopById: (busStopId: string) => Promise<void>;
  addUpdateBusTrip: (trip: NewTrip, tripId: string) => Promise<UIResponse>;
  deleteTrip: (tripId: string) => Promise<void>;
  getTrips: () => Promise<void>;
  getTripById: (tripId: string) => Promise<void>;
  addUpdateTFare: (
    fare: NewTransportFare,
    fareId: string,
  ) => Promise<UIResponse>;
  deleteTFare: (feeId: string) => Promise<void>;
  getTfares: () => Promise<void>;
  getTfareById: (feeId: string) => Promise<void>;
  getDeliveryOrder: (
    query: DeliveryOrderQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  assignDelivery: (orderId: string, agentId: string) => Promise<UIResponse>;
  cancelDelivery: (orderId: string, comment: string) => Promise<UIResponse>;
  addUpdateVehicle: (
    vehicle: NewVehicle,
    vehicleId: string,
  ) => Promise<UIResponse>;
  deleteVehicle: (vehicleId: string) => Promise<void>;
  getVehicles: () => Promise<void>;
  getVehicleById: (vehicleId: string) => Promise<void>;
  getDeliveryByOrderId: (orderId: string) => Promise<void>;
  assignDriverToVehicle: (
    vehicleId: string,
    driverId: string,
  ) => Promise<UIResponse>;
  getPlannedTrips: (
    query: TripQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  assignDriverToTrip: (tripId: string, driverId: string) => Promise<UIResponse>;
  addUpdatePlannedTrip: (
    plannedTrip: NewPlannedTrip,
    plannedTripId: string,
  ) => Promise<UIResponse>;
  getTripRecord: (query: TripQuery) => Promise<void>;
  getNotifications: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  deleteNotification: (notificationId: string) => Promise<void>;
  getTransportOrder: (query: TransportOrderQuery) => Promise<void>;
  getTransportOrderById: (tripId: string) => Promise<void>;
  resetOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  analytics: {
    totalDelivery: 0,
    pending: 0,
    cancelled: 0,
    completed: 0,
  },
  deliveryHistories: [],
  driverTrips: [],
  deliveryAgents: [],
  drivers: [],
  agent: null,
  services: [],
  convenienceFees: [],
  weights: [],
  locations: [],
  busStops: [],
  busTrips: [],
  busStop: null,
  busTrip: null,
  tFares: [],
  tFare: null,
  deliveryOrderItem: null,
  deliveryOrderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  vehicles: [],
  vehicle: null,
  plannedTripList: {
    plannedTrips: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  tripRecord: { summary: {}, tripHistories: {} },
  notifications: [],
  transportOrders: [],
  transportOrderDetail: null,
};

const useTransportationStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        setLoading: async (isLoading) => {
          set({ isLoading });
        },
        setAgent: async (agent) => {
          set({ agent: agent });
        },
        getAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getTransportationAnalytics(query);
          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        addUpdateAgent: async (agent, agentId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateTransportationAgent(
            agent,
            agentId,
          );

          if (res?.isSuccessful) {
            switch (agent?.userType) {
              case 1:
                if (agentId?.length > 1) {
                  set((state) => ({
                    deliveryAgents: state?.deliveryAgents?.map((dAgent) =>
                      dAgent?.agentId === agentId
                        ? { ...dAgent, ...res?.data?.data }
                        : dAgent,
                    ),
                  }));
                } else {
                  set((state) => ({
                    deliveryAgents: [res?.data?.data, ...state?.deliveryAgents],
                  }));
                }
                break;
              case 4:
                if (agentId?.length > 1) {
                  set((state) => ({
                    drivers: state?.drivers?.map((dAgent) =>
                      dAgent?.agentId === agentId
                        ? { ...dAgent, ...res?.data?.data }
                        : dAgent,
                    ),
                  }));
                } else {
                  set((state) => ({
                    drivers: [res?.data?.data, ...state?.drivers],
                  }));
                }
                break;
              default:
                break;
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        getAgents: async (type) => {
          if (type === TRANSPORTATION_AGENTS_TYPE.deliveryAgent) {
            get().getDeliveryAgents();
          } else {
            get().getDrivers();
          }
        },
        getDeliveryAgents: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getProfiledAgent();

          if (res?.isSuccessful) {
            set({
              deliveryAgents: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getDeliveryHistories: async (agentId) => {
          set({ isLoading: true });

          var res: UIResponse = await getAgentDeliveryHistory(agentId);
          if (res?.isSuccessful) {
            set({
              deliveryHistories: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getDriverTrips: async (agentId) => {
          set({ isLoading: true });

          var res: UIResponse = await getTripsAssignedToDriver(agentId);
          if (res?.isSuccessful) {
            set({
              driverTrips: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getDrivers: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getProfiledDrivers();
          if (res?.isSuccessful) {
            set({
              drivers: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getTfares: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAllTransportFares();
          if (res?.isSuccessful) {
            set({
              tFares: res?.data?.data,
            });
          }
          set({ isLoading: false });
        },
        getServices: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAvailableServices();
          if (res?.isSuccessful) {
            set({
              services: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getConvenienceFees: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAvailableConvenienceFees();
          if (res?.isSuccessful) {
            set({
              convenienceFees: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getDeliveryLocations: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAvailableLocations();
          if (res?.isSuccessful) {
            set({
              locations: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getWeights: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAvailableWeights();
          if (res?.isSuccessful) {
            set({
              weights: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getBusStops: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAllBusStops();
          if (res?.isSuccessful) {
            set({
              busStops: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getBusStopById: async (busStopId) => {
          set({ isLoading: true });

          var res: UIResponse = await getBusStopById(busStopId);
          if (res?.isSuccessful) {
            set({
              busStop: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getTrips: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getAllTrips();
          if (res?.isSuccessful) {
            set({
              busTrips: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getTripById: async (tripId) => {
          set({ isLoading: true });

          var res: UIResponse = await getTripById(tripId);
          if (res?.isSuccessful) {
            set({
              busTrip: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getTfareById: async (feeId) => {
          set({ isLoading: true });

          var res: UIResponse = await getTransportFareById(feeId);
          if (res?.isSuccessful) {
            set({
              tFare: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getVehicles: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getVehicles();
          if (res?.isSuccessful) {
            set({
              vehicles: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getVehicleById: async (vehicleId) => {
          set({ isLoading: true });

          var res: UIResponse = await getVehicleById(vehicleId);
          if (res?.isSuccessful) {
            set({
              vehicle: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        addUpdateService: async (service, serviceId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateService(service, serviceId);

          if (res?.isSuccessful) {
            if (serviceId?.length > 1) {
              set((state) => ({
                services: state?.services?.map((service) =>
                  service?.id === serviceId
                    ? { ...service, ...res?.data?.data }
                    : service,
                ),
              }));
            } else {
              set((state) => ({
                services: [res?.data?.data, ...state?.services],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpateBusStop: async (busStop, busStopId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateBusStops(busStop, busStopId);

          if (res?.isSuccessful) {
            if (busStopId?.length > 1) {
              set((state) => ({
                busStops: state?.busStops?.map((stop) =>
                  stop?.id === busStopId
                    ? { ...stop, ...res?.data?.data }
                    : stop,
                ),
              }));
            } else {
              set((state) => ({
                busStops: [res?.data?.data, ...state?.busStops],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateBusTrip: async (trip, tripId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateBusTrip(trip, tripId);

          if (res?.isSuccessful) {
            if (tripId?.length > 1) {
              set((state) => ({
                busTrips: state?.busTrips?.map((trip) =>
                  trip?.id === tripId ? { ...trip, ...res?.data?.data } : trip,
                ),
              }));
            } else {
              set((state) => ({
                busTrips: [res?.data?.data, ...state?.busTrips],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdatePlannedTrip: async (plannedTrip, plannedTripId) => {
          set({ isLoading: true });

          const tripList = get().plannedTripList;

          var res: UIResponse = await addUpdatePlannedTrip(
            plannedTrip,
            plannedTripId,
          );

          if (res?.isSuccessful) {
            get().getPlannedTrips(
              {
                endDate: "",
                search: "",
                startDate: "",
                status: "",
                locationFilter: "",
              },
              tripList?.pageNumber,
              tripList?.pageSize,
            );
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateConvenienceFee: async (fee, feeId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateDeliveryConvenienceFee(
            fee,
            feeId,
          );

          if (res?.isSuccessful) {
            if (feeId?.length > 1) {
              set((state) => ({
                convenienceFees: state?.convenienceFees?.map((fee) =>
                  fee?.id === feeId ? { ...fee, ...res?.data?.data } : fee,
                ),
              }));
            } else {
              set((state) => ({
                convenienceFees: [res?.data?.data, ...state?.convenienceFees],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateDeliveryLocation: async (location, locationId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateDeliveryLocation(
            location,
            locationId,
          );

          if (res?.isSuccessful) {
            if (locationId?.length > 1) {
              set((state) => ({
                locations: state?.locations?.map((location) =>
                  location?.id === locationId
                    ? { ...location, ...res?.data?.data }
                    : location,
                ),
              }));
            } else {
              set((state) => ({
                locations: [res?.data?.data, ...state?.locations],
              }));
            }
          }

          set({ isLoading: false });

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateWeight: async (weight, weightId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateWeightRange(weight, weightId);

          if (res?.isSuccessful) {
            if (weightId?.length > 1) {
              set((state) => ({
                weights: state?.weights?.map((weight) =>
                  weight?.id === weightId
                    ? { ...weight, ...res?.data?.data }
                    : weight,
                ),
              }));
            } else {
              set((state) => ({
                weights: [res?.data?.data, ...state?.weights],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateTFare: async (fare, fareId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateTransportFare(fare, fareId);

          if (res?.isSuccessful) {
            if (fareId?.length > 1) {
              set((state) => ({
                tFares: state?.tFares?.map((fare) =>
                  fare?.id === fareId ? { ...fare, ...res?.data?.data } : fare,
                ),
              }));
            } else {
              set((state) => ({
                tFares: [res?.data?.data, ...state?.tFares],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteBusStop: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteBusStop(id);

          if (res?.isSuccessful) {
            set((state) => ({
              busStops: state?.busStops?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Bus Stop has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteTrip: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteTrip(id);

          if (res?.isSuccessful) {
            set((state) => ({
              busTrips: state?.busTrips?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Bus Trip has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteConvenienceFee: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteConvenienceFee(id);

          if (res?.isSuccessful) {
            set((state) => ({
              convenienceFees: state?.convenienceFees?.filter(
                (item) => item?.id !== id,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Convenience Fee has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteLocation: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteLocation(id);

          if (res?.isSuccessful) {
            set((state) => ({
              locations: state?.locations?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Delivery Location has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteService: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteService(id);

          if (res?.isSuccessful) {
            set((state) => ({
              services: state?.services?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Service has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteWeight: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteWeight(id);

          if (res?.isSuccessful) {
            set((state) => ({
              weights: state?.weights?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Weight Range has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteTFare: async (id: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteTransportFare(id);

          if (res?.isSuccessful) {
            set((state) => ({
              tFares: state?.tFares?.filter((item) => item?.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Transport Fare has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        getDeliveryOrder: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getDeliveryOrders(
            query,
            pageNumber,
            pageSize,
          );
          if (res?.isSuccessful) {
            set({
              deliveryOrderList: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getDeliveryByOrderId: async (orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getDeliveryOrderItem(orderId);
          if (res?.isSuccessful) {
            set({
              deliveryOrderItem: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        assignDelivery: async (orderId, agentId) => {
          set({ isLoading: true });

          var res: UIResponse = await assignToDispatch(orderId, agentId);

          if (res?.isSuccessful) {
            set((state) => ({
              deliveryOrderList: {
                ...state.deliveryOrderList,
                orders: state?.deliveryOrderList?.orders?.map((order) =>
                  order?.orderId === orderId ? { ...res?.data?.data } : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        cancelDelivery: async (orderId, comment) => {
          set({ isLoading: true });

          var res: UIResponse = await cancelDelivery(orderId, comment);

          if (res?.isSuccessful) {
            set((state) => ({
              deliveryOrderList: {
                ...state.deliveryOrderList,
                orders: state?.deliveryOrderList?.orders?.map((order) =>
                  order?.orderId === orderId
                    ? { ...order, orderStatus: "Cancelled" }
                    : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        addUpdateVehicle: async (vehicle, vehicleId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateVehicle(vehicle, vehicleId);

          if (res?.isSuccessful) {
            if (vehicleId?.length > 1) {
              set((state) => ({
                vehicles: state?.vehicles?.map((veh) =>
                  veh?.id === vehicleId ? { ...veh, ...res?.data?.data } : veh,
                ),
              }));
            } else {
              set((state) => ({
                vehicles: [res?.data?.data, ...state?.vehicles],
              }));
            }
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteVehicle: async (vehicleId: string) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteVehicle(vehicleId);

          if (res?.isSuccessful) {
            set((state) => ({
              vehicles: state?.vehicles?.filter(
                (item) => item?.id !== vehicleId,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Vehicle has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        assignDriverToVehicle: async (vehicleId, driverId) => {
          set({ isLoading: true });

          var res: UIResponse = await assignDriverToVehicle(
            vehicleId,
            driverId,
          );

          if (res?.isSuccessful) {
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        getPlannedTrips: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getPlannedTrips(
            query,
            pageNumber,
            pageSize,
          );
          if (res?.isSuccessful) {
            set({
              plannedTripList: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        assignDriverToTrip: async (tripId, driverId) => {
          set({ isLoading: true });

          var res: UIResponse = await assignDriverToTrip(tripId, driverId);

          if (res?.isSuccessful) {
            set((state) => ({
              plannedTripList: {
                ...state.plannedTripList,
                plannedTrips: state?.plannedTripList?.plannedTrips?.map(
                  (trip) =>
                    trip?.id === tripId ? { ...res?.data?.data } : trip,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        getTripRecord: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getDriverTripHistories(query);
          if (res?.isSuccessful) {
            set({
              tripRecord: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getNotifications: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getTransportNotifications();
          if (res?.isSuccessful) {
            set({
              notifications: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        markAsRead: async (notificationId) => {
          set({ isLoading: true });

          var res: UIResponse =
            notificationId?.length > 0
              ? await markTransportNotificationAsRead(notificationId)
              : await markAllTransportNotificationAsRead();

          if (res?.isSuccessful) {
            if (notification?.length < 1) {
              set((state) => ({
                notifications: state?.notifications?.map((notice) => ({
                  ...notice,
                  read: true,
                })),
              }));
            } else {
              set((state) => ({
                notifications: state?.notifications?.map((notice) =>
                  notice?.id === notificationId
                    ? { ...notice, read: true }
                    : notice,
                ),
              }));
            }
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        deleteNotification: async (notificationId: string) => {
          set({ isLoading: true });

          var res: UIResponse =
            await deleteTransportNotification(notificationId);

          if (res?.isSuccessful) {
            set((state) => ({
              notifications: state?.notifications?.filter(
                (item) => item?.id !== notificationId,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Notification has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        getTransportOrder: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getTransportOrder(query);
          if (res?.data?.status) {
            set({
              transportOrders: res?.data?.data == null ? [] : res?.data?.data,
            });
          } else {
            set({ transportOrders: [] });
          }

          set({ isLoading: false });
        },
        getTransportOrderById: async (tripId) => {
          set({ isLoading: true });

          var res: UIResponse = await getTransportOrderById(tripId);
          if (res?.data?.status) {
            set({
              transportOrderDetail: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        reset: () => {
          set({ ...defaultState });
        },
        resetOrder: () => {
          set({ deliveryOrderItem: null });
        },
      }),
      {
        name: "transportationStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useTransportationStore;
