import { addIcon, closeIcon } from "core/consts/images";
import React, { useState, useLayoutEffect, useRef } from "react";

const Accordion = ({
  children,
  title = "Header",
  id = "",
  bodyStyle = "",
}: {
  children?: React.ReactNode;
  title?: string;
  id?: string;
  bodyStyle?: string;
}) => {
  const [clicked, setClicked] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };

  useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = clicked
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [clicked, children]); // Recalculate height when `clicked` or `children` change

  return (
    <div className={`mb-[24px] ${clicked ? "active" : ""}`}>
      <div className="flex items-center justify-between rounded-[5px] border-[1px] border-brand bg-white p-5 shadow-md">
        <p className="text-[14px]">{title}</p>
        <button onClick={handleToggle}>
          <img
            src={clicked ? closeIcon : addIcon}
            loading="lazy"
            alt={clicked ? "close" : "open"}
          />
        </button>
      </div>

      <div
        ref={contentRef}
        id={id}
        className="overflow-hidden transition-all duration-300 ease-in-out"
      >
        <div className={`m-[0px] mt-5 ${bodyStyle}`}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
