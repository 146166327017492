import SelectBox from "core/components/formfields/SelectField";
import { downloadImg } from "core/consts/images";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import SelectField from "core/components/formfields/SelectField";
import DatePicker from "core/components/formfields/DatePicker";
import { LOAN_STATUS } from "core/consts/systemConst";
import { exportToCSV } from "core/helpers/exportToExcel";
import notification from "core/helpers/notification";
import Table from "core/components/table/Table";
import ActionRow from "core/components/table/Actionrow";
import Row from "core/components/table/Row";
import {
  cx,
  formatCurrency,
  getLoanStatusColor,
  getMonthStartEnd,
} from "core/helpers/generalHelpers";
import loansImg from "assets/icons/loans.svg";
import useLoanStore from "core/services/stores/useLoanStore";
import Pagination from "core/components/Pagination";
import { Link, useNavigate } from "react-router-dom";

const LoanServiceManagement = () => {
  const navigate = useNavigate();
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const loanRequestsList = useLoanStore((store) => store.loanRequestList);
  const getLoanRequestAction = useLoanStore((store) => store.getLoanRequests);

  const [isChecked, setIsChecked] = useState(true);
  const [selectedData, setSelectedData] = useState<LoanRequest[]>([]);

  const startEndDate = getMonthStartEnd();

  const [query, setQuery] = useState<LoanQuery>({
    startDate: startEndDate?.start,
    endDate: startEndDate?.end,
    locationId: "",
    showOnlyDefaultedLoans: false,
    status: "",
    userId: "",
  });

  const exportData = async () => {
    if (selectedData?.length > 0) {
      exportToCSV(selectedData, "Loan Requests");

      setSelectedData([]);
      setIsChecked(false);

      notification({
        message: "You have successfully exported the data to excel",
        type: "success",
      });
    } else {
      notification({
        message: "Please select the field you want to export",
        type: "danger",
      });
    }
  };

  const onQueryChange = async (e: any, isAuto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      await getLoanRequestAction(
        { ...query, [name]: value },
        loanRequestsList?.pageNumber,
        loanRequestsList?.pageSize,
      );
    }
  };

  const fetchMore = async (page: number) => {
    await getLoanRequestAction({ ...query }, page, loanRequestsList?.pageSize);
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getLoanRequestAction(
      query,
      loanRequestsList?.pageNumber,
      loanRequestsList?.pageSize,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[28px] flex flex-col justify-start lg:flex-row lg:justify-between">
        <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
          <img src={loansImg} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Loan Service Management
          </h5>
        </div>
        <div className="flex items-center gap-5">
          <Link
            className="text-brand hover:underline"
            to="/superadmin/loan-management/loan-parameter"
          >
            Loan Parameters
          </Link>
          <Link
            className="text-brand hover:underline"
            to="/superadmin/loan-management/settings"
          >
            Settings
          </Link>
        </div>
      </header>

      <div className="mb-[24px] flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full lg:w-8/12">
          {/* 
          <section>
            <form
              className="flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
              onSubmit={search}
            >
              <InputField
                label="First Name"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="firstName"
                value={query?.firstName}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Last Name"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="firstName"
                value={query?.lastName}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Email"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="email"
                value={query?.email}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Phone Number"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="phoneNumber"
                value={query?.phoneNumber}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <div className="flex items-center gap-3">
                <button
                  disabled
                  className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3 px-8 text-white"
                >
                  <span className="text-[14px text-brand">Search</span>
                </button>
              </div>
            </form>
          </section>
          */}

          <section>
            <Table
              headers={[
                "checkbox",
                "First Name",
                "Last Name",
                "Pre-approved Amount",
                "Request Amount",
                "Loan Eligibility",
                "Student Levels",
                "Decision",
              ]}
              isChecked={isChecked}
              onCheck={(e: any) => {
                setIsChecked(e?.target?.checked);

                if (e?.target?.checked) {
                  setSelectedData([...loanRequestsList?.data]);
                } else {
                  setSelectedData([]);
                }
              }}
            >
              {loanRequestsList?.data?.length > 0 ? (
                loanRequestsList?.data?.map((request) => (
                  <tr key={request?.id}>
                    <ActionRow style={`flex items-center justify-center`}>
                      <input
                        type="checkbox"
                        className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
                        checked={selectedData?.some(
                          (loan) => loan?.id === request?.id,
                        )}
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            setSelectedData((state) => [
                              ...state,
                              { ...request },
                            ]);
                          } else {
                            setSelectedData((state) =>
                              state.filter((el) => el.id !== request?.id),
                            );
                          }
                        }}
                      />
                    </ActionRow>
                    <Row value={request?.user?.firstName} />
                    <Row value={request?.user?.lastName} />
                    <Row value={formatCurrency(request?.eligibleAmount)} />
                    <Row value={formatCurrency(request?.loanAmount)} />
                    <Row value={"N/A"} />
                    <Row value={"N/A"} />
                    <ActionRow
                      style={`flex items-center justify-between gap-3`}
                    >
                      <span
                        className={cx(
                          getLoanStatusColor(request?.status?.toLowerCase()),
                        )}
                      >
                        {request?.status}
                      </span>

                      <button
                        className={cx(getLoanStatusColor(""))}
                        onClick={() =>
                          navigate(
                            `/superadmin/loan-management/requests/${request?.id}`,
                          )
                        }
                      >
                        View
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <ActionRow style={`flex items-center justify-center`}>
                    <input
                      disabled
                      type="checkbox"
                      className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
                      name=""
                      id=""
                    />
                  </ActionRow>
                  <Row colSpan={8} value="no loan request yet" />
                </tr>
              )}
            </Table>

            <Pagination
              pageNumber={loanRequestsList?.pageNumber}
              pageSize={loanRequestsList?.pageSize}
              totalCount={loanRequestsList?.totalCount}
              totalPage={loanRequestsList?.totalPage}
              onFetch={fetchMore}
            />
          </section>
        </div>
        <div className="w-full lg:w-4/12">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="locationId"
            defaultName="Select your Location"
            defaultValue=""
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.id,
                    })),
                  ]
                : []
            }
            value={query?.locationId}
            onChange={(e: any) => onQueryChange(e, false)}
          />

          <SelectBox
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="status"
            defaultName="Filter By Status"
            defaultValue=""
            options={LOAN_STATUS?.length > 0 ? [...LOAN_STATUS] : []}
            value={query?.status}
            onChange={(e: any) => onQueryChange(e, false)}
          />

          <SelectBox
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="showOnlyDefaultedLoans"
            defaultName=""
            defaultValue="Filter by repayment status"
            options={[
              { name: "Show all loan", value: false },
              { name: "Show defaults Only", value: true },
            ]}
            value={query?.showOnlyDefaultedLoans}
            onChange={(e: any) => onQueryChange(e, false)}
          />

          <div className="mb-[18px] grid grid-cols-2 gap-5 lg:grid-cols-2">
            <DatePicker
              name="startDate"
              label="Start Date"
              value={query?.startDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => onQueryChange(e, false)}
            />
            <DatePicker
              name="endDate"
              label="End Date"
              value={query?.endDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => {
                onQueryChange(e, false);
              }}
            />
          </div>

          <button
            className="mb-[18px] flex w-full items-center justify-center rounded-[5px] border-brand bg-brand-400 p-3 text-brand disabled:cursor-not-allowed"
            onClick={() => fetchMore(loanRequestsList.pageNumber)}
          >
            <span className="text-[14px]">Search</span>
          </button>

          <button
            disabled={selectedData?.length < 1}
            className="flex w-full items-center justify-between rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
            onClick={() => exportData()}
          >
            <span className="text-[14px]">Download</span>
            <img src={downloadImg} loading="lazy" alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default LoanServiceManagement;
