import { cx } from "core/helpers/generalHelpers";
import React, { useRef, useEffect } from "react";

function TextField({
  boxStyle = "",
  textareaStyle = "",
  value = "",
  name = "",
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  label = "",
  rows = 3,
  error = "",
  enableAction = false,
  placeholder = "",
  isRequired = false,
  instruction,
}: {
  boxStyle?: string;
  textareaStyle?: string;
  value: string;
  name: string;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  label?: string;
  rows?: number;
  error?: string;
  enableAction?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  ref?: any;
  instruction?: string;
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className={cx("relative", boxStyle)}>
      {label && label?.length > 0 && (
        <label htmlFor={name} className={`mb-2 text-[14px] text-brandgray`}>
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}
      <div>
        <textarea
          className={cx(
            "h-12 w-full rounded-[5px] border-[1px] border-brandgray bg-white p-3 text-sm outline-none disabled:cursor-not-allowed",
            enableAction ? "w-10/12 truncate border-b-0" : "w-full",
            textareaStyle,
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          ref={textAreaRef}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      <span className="text-[14px] text-red-500">{error}</span>
      <span className="text-xs text-brandgray">{instruction}</span>
    </div>
  );
}

export default TextField;
